<template>
  <div class="m-input-autocomplete">
    <div
      class="sf-input-has-clear"
      v-click-outside="closeHandler"
    >
      <SfInput
        ref="input"
        :label="currentLabel"
        @input="onInput"
        class="sf-input--filled"
        :class="inputClass"
        :value="inputValue"
        :disabled="disabled"
        :placeholder="prevInputValue[property]"
        data-transaction-name="Autocomplete - Field"
        @focus="onFocus"
        @blur="onBlur"
        @click.native="toggleAutocomplete"
      />

      <SfButton
        data-transaction-name="Autocomplete - Clear"
        @click.native="clearField"
        class="sf-button--pure sf-input-has-clear__button"
        type="button"
      >
        <span class="close-icon" />
      </SfButton>
      <div
        v-if="isOpenAutocomplete"
        class="m-input-autocomplete__autocomplete"
      >
        <p
          class="m-input-autocomplete__autocomplete-no-result"
          v-if="isNoResult"
        >
          {{ message }}
        </p>
        <ul
          class="m-input-autocomplete__autocomplete-result"
          v-else
        >
          <li class="m-input-autocomplete__loader" v-if="isLoadingInput">
            <slot name="loader">
              <SfLoader />
            </slot>
          </li>
          <li
            v-else
            v-for="(item, i) in dataList"
            :key="item.id || i"
            class="m-input-autocomplete__autocomplete-item"
            data-transaction-name="Autocomplete - Set Input"
            @click="setInputValue(item)"
          >
            {{ item[property] }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import SfInput from 'theme/components/storefront-override/SfInput'
import { clickOutside } from '@storefront-ui/vue/src/utilities/directives';
import SfLoader from 'theme/components/storefront-override/SfLoader.vue';
import { SfButton } from '@storefront-ui/vue';

export default {
  name: 'MInputAutocompleteAsync',
  components: {
    SfLoader,
    SfButton,
    SfInput
  },
  props: {
    /**
     * selected object
     */
    selected: {
      type: [Object, null],
      default: () => ({})
    },
    /**
     * objects array
     */
    dataList: {
      type: Array,
      default: () => [],
      required: true
    },
    /**
     * property for value
     */
    property: {
      type: String,
      default: '',
      required: true
    },
    /**
     * label
     */
    label: {
      type: String,
      default: '',
      required: true
    },
    /**
     * other label
     */
    labelTop: {
      type: String,
      default: '',
      required: false
    },
    /**
     * message if there are no results
     */
    message: {
      type: String,
      default: '',
      required: true
    },
    /**
     * border highlight
     */
    disabled: {
      type: Boolean,
      default: () => false
    },
    highlight: {
      type: Boolean,
      default: () => false
    },
    searchMode: {
      type: Boolean,
      default: () => false
    },
    isLoadingInput: {
      type: Boolean,
      default: () => false
    }
  },
  directives: {
    clickOutside
  },
  data () {
    return {
      isOpenAutocomplete: false,
      inputValue: '',
      prevInputValue: {},
      isInputFilled: false,
      focused: false
    }
  },
  watch: {
    selected () {
      this.prevInputValue = this.selected ? { ...this.selected } : {}
    }
  },
  computed: {
    currentLabel () {
      return this.selected?.[this.property] ? this.otherLabel : this.label
    },
    otherLabel () {
      return this.labelTop ? this.labelTop : this.label
    },
    isNoResult () {
      return !this.dataList.length;
    },
    inputClass () {
      return {
        'sf-input--has-text': this.prevInputValue[this.property],
        'sf-input--focused': this.highlight && (this.focused || this.isOpenAutocomplete)
      }
    }
  },
  methods: {
    clearField () {
      this.isOpenAutocomplete = false
      this.prevInputValue = ''
      this.inputValue = ''
      this.isInputFilled = false
      this.$emit('input', null)
      this.$emit('set-value', null)
      this.focusInput()
    },
    toggleAutocomplete () {
      if (this.disabled) return;

      if (this.searchMode && !this.inputValue.length) {
        this.isOpenAutocomplete = false
        return
      }

      this.isOpenAutocomplete = !this.isOpenAutocomplete

      if (!this.isOpenAutocomplete) {
        this.inputValue = ''
      } else {
        this.focusInput()
      }
    },
    focusInput () {
      this.$refs.input?.$el?.getElementsByTagName('input')?.[0]?.focus()
    },
    setInputValue (value) {
      this.isOpenAutocomplete = false
      this.prevInputValue = value
      this.inputValue = ''
      this.isInputFilled = true
      this.$emit('set-value', value)
    },
    startAutocomplete () {
      this.$emit('input', `${this.inputValue}`.trim())
    },
    onFocus (value) {
      this.focused = true
      const that = value.currentTarget;
      that.selectionStart = that.selectionEnd = this.inputValue.length;
    },
    onBlur () {
      this.focused = false
    },
    onInput (value) {
      this.inputValue = value
      this.isOpenAutocomplete = !!value.length
      this.isInputFilled = false
      this.startAutocomplete()
    },
    closeHandler () {
      if (!this.isOpenAutocomplete) {
        return
      }

      this.isOpenAutocomplete = false

      if (this.isInputFilled || !this.prevInputValue[this.property]) {
        return
      }

      this.startAutocomplete()

      if (!this.dataList.length) {
        this.startAutocomplete()
        this.isInputFilled = false
        this.$emit('set-value', this.prevInputValue)
      }

      this.inputValue = ''
    }
  },
  mounted () {
    this.inputValue = ''
    this.prevInputValue = this.selected ? { ...this.selected } : {}
    this.isInputFilled = !!this.prevInputValue[this.property]
  }
}
</script>

<style lang="scss">
@import "$ui/styles/components/molecules/SfSelect.scss";
</style>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-input-autocomplete {
  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50px;
  }

  &__autocomplete {
    font-size: var(--font-sm);
    font-weight: var(--font-normal);
    line-height: 1.05rem;
    list-style: none;
    padding: 0;
    background: var(--light-gray);
    margin: 2px 0 0 0;
    top: 100%;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    z-index: var(--autocomplete-index);
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.06);

    li {
      cursor: pointer;
    }

    &-result {
      padding: 0;
      margin: 0;
      max-height: 10.938rem;
      overflow-y: auto;
      list-style: none;
      box-sizing: border-box;
    }

    &-item {
      padding: 0 var(--spacer-15) var(--spacer-15) var(--spacer-15);
      outline: none;
      -webkit-tap-highlight-color: rgba(0,0,0,0);

      &:first-child {
        padding-top: var(--spacer-15);
      }

      &:hover {
        color: var(--orange);
      }

      &:active, &:focus {
        outline: none;
      }
    }

    ::-webkit-scrollbar {
      width: 3px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-left: 3px solid var(--light-orange);
      background-clip: padding-box;

      &:hover {
        border-left-color: var(--orange);
      }
    }

    &-no-result {
      margin: 0;
      padding: var(--spacer-15);
    }
  }

  ::v-deep {
    .sf-input {
      &__error-message {
        display: none;
      }

      &__label {
        width: calc(100% - var(--spacer-30));
      }

      &--filled input{
        &::placeholder {
          color: var(--black);
        }
      }

      input {
        --input-padding: 1.5rem var(--spacer-45) .69rem var(--spacer-15);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &:focus::placeholder {
          color: var(--input-label-color, inherit);
        }
      }
    }
  }
}

::v-deep {
  .sf-input__wrapper {
    margin-bottom: 0;
  }

  .sf-input {
    transition: border 200ms;
    border: 1px solid var(--light-gray);
  }

  .sf-input--focused {
    border: 1px solid var(--orange)
  }
}
</style>
