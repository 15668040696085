<template>
  <div class="shp-area-nova">

    <div
      v-if="config.newPostTypesList.length > 1"
      class="radio-switcher-simple shp-area-nova__types"
    >
      <SfRadio
        v-for="item of config.newPostTypesList"
        :key="item.value"
        :selected="draft.npType"
        :value="item.value"
        name="delivery-method"
        class="radio-switcher-simple__input"
        @input="setNewPostType"
      >
        <template #label>
          <div class="sf-radio__label radio-switcher-simple__label">
            <div>{{ $t(item.label) }}</div>
          </div>
        </template>
      </SfRadio>
    </div>

    <MInputAutocompleteAsync
      :highlight="true"
      :selected="draft.npCity"
      :disabled="loadingNewPost"
      :data-list="getNpCityList"
      :label="$t('Locality city')"
      :label-top="$t('Locality city')"
      :message="$t('No results were found.')"
      :is-loading-input="loadingNewPostInput"
      class="shp-area-nova__input shp-area-nova__input--city"
      property="description"
      :search-mode="true"
      @set-value="setCity"
      @input="inputCity"
    >
      <template #loader>
        <ShippingLoading />
      </template>
    </MInputAutocompleteAsync>

    <ShippingAutocomplete
      v-if="draft.npCity && draft.npType === 'address'"
      :selected="draft.address"
      :data-list="addressList"
      :disabled="loadingNewPost"
      :label="$t('Shipping address')"
      :loading-delivery="loadingDelivery"
      :loading-delivery-input="loadingDeliveryInput"
      :error-trigger="error.deliveryPointNotFound"
      @input="inputText"
      @set-value="setAddress"
      @clear="clear"
    />

    <MInputAutocomplete
      v-else-if="draft.npCity"
      :highlight="true"
      :selected="draft.npShop"
      :data-list="getNpShopList"
      :label="$t(autocompletePlaceLabel)"
      :label-top="$t(autocompletePlaceLabel)"
      :valid="!validationError"
      :disabled="loadingNewPost"
      :limit="50"
      :error-message="validationError"
      :message="$t('No results were found.')"
      class="shp-area-nova__input shp-area-nova__input--shop"
      property="label"
      @set-value="setShop"
    >
      <template #item="{item, property, setInputValue}">
        <ShippingNovaShopItem
          :is-poshtomat="draft.npType === 'poshtomat'"
          :property="property"
          :is-cart-has-alcohol="isCartHasAlcohol"
          :total-weight-summary="totalWeightSummary"
          :item="item"
          @set="setInputValue"
        />
      </template>
    </MInputAutocomplete>
  </div>
</template>

<script>
import ShippingAutocomplete from '$modules/shipping/components/shipping-autocomplete.vue';
import MInputAutocompleteAsync from 'theme/components/molecules/m-input-autocomplete-async.vue';
import MInputAutocomplete from 'theme/components/molecules/m-input-autocomplete.vue';
import ShippingNovaMixin from '$modules/shipping/mixins/shipping-nova-mixin';
import { SfRadio } from '@storefront-ui/vue';
import ShippingLoading from '$modules/shipping/components/shipping-loading.vue';
import ShippingNovaShopItem from '$modules/shipping/components/shipping-nova-shop-item.vue';

export default {
  name: 'ShippingAreaNova',
  components: { ShippingNovaShopItem, ShippingLoading, MInputAutocomplete, MInputAutocompleteAsync, ShippingAutocomplete, SfRadio },
  mixins: [ShippingNovaMixin]
}
</script>

<style scoped lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/mixins";
@import "~theme/css/px2rem";

.shp-area-nova {
  &__types {
    display: flex;
    gap: 20px
  }

  &__input {
    &--city {
      margin-bottom: 15px;
    }

    &--shop {
      padding-top: var(--spacer-xs);

      ::v-deep {
        .sf-input__error-message {
          height: auto;
        }
      }
    }
  }
}
</style>
