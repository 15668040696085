import { mapActions, mapGetters, mapState } from 'vuex';
import pointInPolygon from 'point-in-polygon';
import { calculateSquare, remapBounds } from '$modules/shipping/helpers/shipping-remap';
import config, { deliveryMethods } from '$modules/shipping/config';
import { getProductWeight } from 'theme/helpers/product';
import { weightGToKg } from 'theme/filters';

export default {
  name: 'ShippingModuleMixin',
  data () {
    return {
      getCityList: [],
      getShopList: [],
      lastRequestDate: null,
      config,
      pickupGroups: [],
      availableMethods: [],
      addressList: [],
      initialized: false,
      loading: {
        moduleInit: true,
        cityChange: false,
        saveShipping: false,
        delivery: false,
        deliveryInput: false,
        newPost: false,
        newPostInput: false,
        userLocate: false
      },
      error: {
        locateError: false,
        shopNotFound: false,
        deliveryNotFound: false,
        deliveryPointNotFound: false
      },
      buffer: {},
      bufferNP: {}
    }
  },
  props: {
    isDraftMode: {
      type: Boolean,
      default: () => false
    },
    productToAdd: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    ...mapGetters({
      getShippingMethodList: 'shipping/getShippingMethods',
      isDraftValid: 'shipping-module/isDraftValid'
    }),
    ...mapState({
      defaultCity: state => state['shipping-module'].defaultCity,
      getShopListLoading: state => state['shipping-module'].loading.shopList,
      getNpCenter: state => state['shipping-module'].npCenter,
      getDeliveryPolygon: state => state['shipping-module'].deliveryPolygon,
      getCurrent: state => state['shipping-module'].current,
      getDraft: state => state['shipping-module'].draft,
      getNpShopList: state => state['shipping-module'].npShopList,
      productsInCart: state => state.cart.cartItems
    }),
    cityLabel () {
      return deliveryMethods.includes(this.getDraft.method)
        ? 'Your town'
        : 'City'
    },
    isVarusShipping () {
      return this.checkVarusShipping(this.getDraft.method)
    },
    getShippingModuleLoading () {
      return this.loading.moduleInit ||
        this.getShopListLoading ||
        this.loading.cityChange
    },
    hasMethods () {
      return {
        DELIVERY: true,
        PICKUP: true,
        DELIVERY_NOVA: true
      }
    },
    getShippingMethods () {
      return this.getShippingMethodList.sort((a, b) => {
        if (a.priority < b.priority) return -1
        if (a.priority > b.priority) return 1

        return 0
      })
    },
    autocompleteBounds () {
      if (!this.getDeliveryPolygon.list.length) {
        return remapBounds({
          north: this.map.center.lat + 0.1,
          south: this.map.center.lat - 0.1,
          east: this.map.center.lng + 0.1,
          west: this.map.center.lng - 0.1
        })
      }

      const square = calculateSquare(this.getDeliveryPolygon.list)

      return remapBounds(square)
    },
    totalWeightSummary () {
      const weight = this.productsInCart.reduce((result, product) => {
        return result + (getProductWeight(product) * product.qty);
      }, 0);

      return weightGToKg(weight)
    },
    isNewPostOverweight () {
      if (this.getDraft.method !== this.config.codes.newPost) return false

      if (!this.getDraft.npShop) return false

      return this.totalWeightSummary > this.getDraft.npShop.max_weight
    },
    isCartHasAlcohol () {
      return !!this.productsInCart.find(i => i.is_18_plus)
    },
    getDefaultCity () {
      const defaultCity = { ...(this.defaultCity || {}) }

      if (!defaultCity?.id) return defaultCity

      defaultCity.deliveryName = `${defaultCity.name} ${this.$t('and moving')}`

      return defaultCity
    },
    cityList () {
      return this.getCityList
        .map(i => ({
          ...i,
          deliveryName: `${i.name} ${this.$t('and moving')}`
        }))
    },
    cityShippingList () {
      return [...this.cityList]
        .filter(i => i.is_delivery_region)
    },
    cityListByType () {
      if (!this.isVarusShipping || this.getDraft.method === this.config.codes.pickup) {
        return this.cityList
      }

      return this.cityShippingList
    }
  },
  methods: {
    ...mapActions({
      loadCityListPure: 'shipping-module/loadCityListPure',
      loadShopListPure: 'shipping-module/loadShopListPure',
      findCityByName: 'shipping-module/findCityByName',
      loadLocalizedCities: 'shipping-module/loadLocalizedCities',
      loadGeocodeByCoordinate: 'shipping-module/loadGeocodeByCoordinate',
      loadGeoLocateShop: 'shipping-module/loadGeoLocateShop',
      loadCityPolygons: 'shipping-module/loadCityPolygons',
      loadGeocodeByText: 'shipping-module/loadGeocodeByText',
      setDraftCity: 'shipping-module/setDraftCity',
      setDraftMethod: 'shipping-module/setDraftMethod',
      setDraftShop: 'shipping-module/setDraftShop',
      setDraftType: 'shipping-module/setDraftType',
      setDraftTypeGroup: 'shipping-module/setDraftTypeGroup',
      setDraftAddress: 'shipping-module/setDraftAddress',
      saveShipping: 'shipping-module/saveShipping',
      initDraft: 'shipping-module/initDraft',
      loadNewPostCity: 'shipping-module/loadNewPostCity',
      loadNewPostWarehouse: 'shipping-module/loadNewPostWarehouse',
      setNewPostCity: 'shipping-module/setNewPostCity',
      setNewPostShop: 'shipping-module/setNewPostShop',
      setNewPostTypeHandler: 'shipping-module/setNewPostType'
    }),
    async initShippingModule () {
      try {
        const city = this.isDraftMode
          ? (this.getDraft.city || this.getCurrent?.city || this.getDefaultCity)
          : (this.getCurrent?.city || this.getDefaultCity)

        if (this.getCurrent.method) {
          await this.setDraftMethod({ method: this.getCurrent.method })
        }

        if (this.getCurrent.npType) {
          await this.setNewPostTypeHandler(this.getCurrent.npType)
        }

        const buffer = {
          ...(this.isDraftMode && this.getDraft?.shop ? this.getDraft : this.getCurrent)
        }

        const toPromise = [
          this.loadCityListPure({}),
          this.setShippingCity(city),
          this.loadLocalizedCities()
        ]

        if (this.getCurrent?.npCity?.ref) {
          toPromise.push(
            this.restoreNovaCity(this.getCurrent?.npCity?.ref, this.getCurrent?.npType)
          )
        }

        const [
          cityList
        ] = await Promise.all(toPromise)

        this.getCityList = cityList

        await this.initDraft(buffer)
      } catch (e) {
      } finally {
        this.loading.moduleInit = false
        this.initialized = true
      }
    },
    checkVarusShipping (method) {
      return !this.config.newPostMethods.includes(method)
    },
    async setShippingCity (city, second = false) {
      try {
        this.loading.cityChange = true
        this.clearErrors()

        await this.setDraftType({ type: null })
        await this.setDraftAddress({ address: null })
        await this.setDraftShop({ shop: null })
        await this.setDraftTypeGroup({ group: [] })
        await this.setDraftCity({ city })

        const [
          shopList
        ] = await Promise.all([
          this.loadShopListPure({ cityId: city?.id }),
          this.loadCityPolygons({ tms_id: city?.tms_id })
        ])

        this.getShopList = shopList?.items || []

        this.prepareData()

        if (this.hasMethods.PICKUP) {
          if (!this.pickupGroups.length) return

          return this.setTypeHandler(this.pickupGroups?.[0]?.type)
        }

        if (second) return

        if (this.getDraft.method === this.config.codes.delivery && this.hasMethods.DELIVERY) return
        if (this.getDraft.method === this.config.codes.pickup && this.hasMethods.PICKUP) return
        if (this.getDraft.method === this.config.codes.newPost) return

        return this.setShippingCity(this.getDefaultCity, true)
      } catch (e) {
      } finally {
        this.loading.cityChange = false
      }
    },
    async switchShippingMethod (geocode) {
      if (this.getDraft.method === this.config.codes.newPost) return

      const city = await this.findCityByName({ name: geocode?.settlement })

      if (this.getDraft.city?.tms_id === city?.tms_id) return

      const newCity = this.getCityList.find(i => i.tms_id === city?.tms_id)

      if (!newCity) return

      await this.setShippingCity(newCity)
    },
    async setShippingMethod (method) {
      if (this.getDraft.method) {
        this.buffer[this.getDraft.method] = JSON.parse(JSON.stringify(this.getDraft))
      }

      const buffer = this.buffer[method]

      await this.setDraftShop({ shop: null })
      await this.setDraftType({ type: null })
      await this.setDraftTypeGroup({ group: [] })

      const checkShipping = this.cityShippingList.find(i => i.id === this.getDraft.city?.id)

      this.clearErrors()

      await this.setDraftMethod({ method })

      const isPickup = method === this.config.codes.pickup
      const isVarusShipping = this.checkVarusShipping(method)

      if (buffer && isVarusShipping) {
        await this.setShippingCity(buffer?.city)
      } else if (isVarusShipping && !isPickup && !checkShipping) {
        await this.setShippingCity(this.getDefaultCity)

        return
      }

      if (buffer && isVarusShipping) {
        await this.setDraftShop({ shop: buffer?.shop })
        await this.setDraftType({ type: buffer?.type })
        await this.setDraftTypeGroup({ group: buffer?.group })
        await this.setDraftAddress({ address: buffer?.address })
      } else if (buffer) {
        await this.initDraft({
          ...buffer,
          npShop: null
        })

        await this.setNewPostShop(buffer.npShop)
        return
      }

      if (this.getCurrent.city?.id !== this.getDraft.city?.id || method !== this.getCurrent?.method) return

      await this.setDraftShop({ shop: buffer?.shop || this.getCurrent?.shop })
      await this.setDraftType({ type: buffer?.type || this.getCurrent?.type })
      await this.setDraftTypeGroup({ group: buffer?.group || this.getCurrent?.group })
      await this.setDraftAddress({ address: buffer?.address || this.getCurrent?.address })
    },
    prepareData () {
      const ctx = this.getShopList.reduce((a, b) => {
        const keys = b?.delivery_methods?.map(i => i.key) || []

        for (const key of keys) {
          const isPickup = this.config.pickupMethods.includes(key)

          if (!a.availableMethods[key]) a.availableMethods[key] = true
          if (!a.pickupGroups[key] && isPickup) a.pickupGroups[key] = [b]

          else if (isPickup) a.pickupGroups[key].push(b)
        }

        return a
      }, { pickupGroups: {}, availableMethods: {} })

      this.pickupGroups = Object.keys(ctx.pickupGroups)
        .map(i => ({
          type: i,
          disabled: !ctx.pickupGroups[i].length,
          list: ctx.pickupGroups[i]
        }))

      this.availableMethods = Object.keys(ctx.availableMethods)
    },
    async setShopFromMap (event) {
      if (!event.shopId || !event.type) return

      const shop = this.getShopList.find(i => i.id === event.shopId) || null

      await this.setDraftShop({ shop })
      await this.setDraftType({ type: event.type })
      await this.setDraftTypeGroup({ group: [] })
    },
    async setNpShopFromMap (event) {
      if (!event.shopId) return

      const shop = this.getNpShopList.find(i => i.id === event.shopId) || null

      return this.setNewPostShop(shop)
    },
    async getDeliveryLocate (geocode) {
      try {
        if (!this.checkPointAutocomplete(geocode)) {
          await this.setDeliveryError(geocode)
          return null
        }

        const locate = await this.loadGeoLocateShop({
          lng: geocode.coordinates[0],
          lat: geocode.coordinates[1],
          cityId: this.getDraft.city.id
        })

        if (!locate) {
          await this.setDeliveryError(geocode)
          return null
        }

        return locate
      } catch (e) {
        return null
      }
    },
    clearErrors () {
      this.error.locateError = false
      this.error.shopNotFound = false
      this.error.deliveryNotFound = false
      this.error.deliveryPointNotFound = false
    },
    async setAddress (geocode) {
      await this.switchShippingMethod(geocode)

      return this.setDraftAddress({ address: geocode })
    },
    setAddressHandler (geocode) {
      this.clearErrors()

      this.setAddress(geocode)
    },
    async visicomDeliveryRequest (lat, lng) {
      try {
        const data = await this.loadGeocodeByCoordinate({ lat, lng })

        return data || null
      } catch (e) {
        return null
      }
    },
    async setDeliveryCoordinates (coordinates) {
      const date = Date.now()
      try {
        this.lastRequestDate = date
        this.loading.delivery = true

        await this.setDraftType({ type: null })
        await this.setDraftShop({ shop: null })
        await this.setDraftTypeGroup({ group: [] })

        const [lng, lat] = this.getDraft.address?.coordinates || []

        if (lat === coordinates.lat && lng === coordinates.lng) return

        if (
          this.getDraft.address?.coordinates?.lat === coordinates.lat &&
          this.getDraft.address?.coordinates?.lng === coordinates.lng
        ) return

        const geocode = await this.visicomDeliveryRequest(coordinates.lat, coordinates.lng)

        if (!geocode?.label) return this.setDeliveryError(geocode)

        if (this.lastRequestDate !== date) return

        await this.switchShippingMethod(geocode)

        this.clearErrors()
        this.addressList = []

        return this.setDraftAddress({ address: geocode })
      } catch (e) {} finally {
        if (this.lastRequestDate === date) {
          this.loading.delivery = false
        }
      }
    },
    setDeliveryError (address = null) {
      if (address) {
        this.error.deliveryNotFound = true
      } else {
        this.error.deliveryPointNotFound = true
      }

      return this.setDraftAddress({ address })
    },
    deliveryMapMove () {
      this.lastRequestDate = Date.now()
      this.loading.delivery = false
    },
    checkPointAutocomplete (item) {
      if (!this.autocompleteBounds.length) return true
      if (!item.label || !item.coordinates) return false

      return pointInPolygon(item.coordinates, this.autocompleteBounds)
    },
    async loadAddressList ({ lng, lat, text }) {
      try {
        this.loading.deliveryInput = true

        this.addressList = await this.loadGeocodeByText({ lng, lat, text })
          .then(d => d?.filter(this.checkPointAutocomplete) || [])
      } catch (e) {
        this.addressList = []
      } finally {
        this.loading.deliveryInput = false
      }
    },
    async autocompleteNewPost ({ text }) {
      try {
        if (!text) return

        this.loading.newPostInput = true

        return await this.loadNewPostCity({
          q: text
        })
      } finally {
        this.loading.newPostInput = false
      }
    },
    async setNovaCity (value) {
      try {
        this.loading.newPost = true
        await this.setNewPostCity(value)
      } finally {
        this.loading.newPost = false
      }
    },
    async restoreNovaCity (ref, npType) {
      try {
        this.loading.newPost = true
        await this.loadNewPostWarehouse({
          ref,
          npType
        })
      } finally {
        this.loading.newPost = false
      }
    },
    setNovaShop (value) {
      this.setNewPostShop(value)
    },
    async setNewPostType (value) {
      try {
        this.loading.newPost = true

        this.bufferNP[this.getDraft.npType] = JSON.parse(JSON.stringify({
          npCity: this.getDraft.npCity,
          npShop: this.getDraft.npShop
        }))

        const bufferNP = this.bufferNP[value]

        await this.setNewPostTypeHandler(value)

        if (bufferNP?.npCity) {
          await this.setNewPostCity(bufferNP.npCity)
          await this.setNewPostShop(bufferNP.npShop)
        } else {
          await this.setNewPostShop(null)
        }
      } finally {
        this.loading.newPost = false
      }
    },
    async saveDeliveryShipping () {
      const locate = await this.getDeliveryLocate(this.getDraft.address)

      if (!locate) {
        this.error.shopNotFound = true
        return
      }

      await this.setDraftShop({ shop: locate.shop })
      await this.setDraftType({ type: locate.key })
      await this.setDraftTypeGroup({ group: locate.group })

      await this.saveBaseShipping()
      this.$emit('close')
    },
    async saveDeliveryNova () {
      try {
        const shop = this.getDraft.npShop

        const locate = await this.loadGeoLocateShop({
          lng: +shop.longitude,
          lat: +shop.latitude,
          typeDelivery: this.config.codes.newPost.toLowerCase()
        })

        if (!locate) {
          this.error.shopNotFound = true
          return
        }

        await this.setDraftShop({ shop: locate.shop })
        await this.setDraftType({ type: locate.key })
        await this.setDraftTypeGroup({ group: locate.group })

        await this.saveBaseShipping()
        this.$emit('close')
      } catch (e) {
      }
    },
    async saveBaseShipping () {
      if (!this.isDraftMode) {
        void this.saveShipping({
          productToAdd: this.productToAdd
        })
      }

      this.$emit('close')
    },
    requestGeolocation () {
      try {
        if (this.loading.userLocate) return

        this.loading.userLocate = true

        const successCallback = async (position) => {
          try {
            const latLng = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            }

            await this.setDeliveryCoordinates(latLng)
          } catch (e) {} finally {
            this.loading.userLocate = false
          }
        };

        const errorCallback = () => {
          this.error.locateError = true
          this.loading.userLocate = false
        };

        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
      } catch (e) {}
    }
  }
}
